@charset "utf-8";

#logo {
    background: 0 0 no-repeat;
}

.drug-right h1 {
    margin: 5px 0;
}

.fndpanel {
    height: 25px !important;
}

.drugs td {
    padding: 2px 3px;
    width: 200px;
}

/* NEW */
.drug-top li span {
    float: right;
}

.drug-top li a {
    display: block;
}

.drug-top {
    font-size: 1.1em;
    line-height: 18px;
    width: 100%;
}

.drug-top td {
    width: 50%;
    padding: 0 50px 0 30px;
}

.drug-top ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.drugs b {
    color: #ee0000;
    display: block;
    margin-left: -20px;
    position: absolute;
}

.drugdesc h2 {
    margin-top: 10px !important;
}

.drugdesc ul {
    position: relative;
    right: 24px;
}

table.drugs td {
    vertical-align: top;
}

.drugdesc tr td:first-child {
    width: 49%;
}

.drugdesc tr td:nth-child(2) {
    padding-left: 20px;
}

.pharm_stats th, .druginfo th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-right: 20px;
}

.pharm_stats, .druginfo table {
    border-collapse: collapse;
}

.pharm_stats td, .druginfo td {
    border-bottom: 1px solid #ccc;
    padding-right: 20px;
}

.drug_h2 {
    margin-top: 0px !important;
}

.manufinfo {
    margin-bottom: 10px;
    overflow: hidden;

}

.manuf-left {
    float: left;
    width: 320px;
}

.manuf-right {
    float: left;
    width: 400px;
}

.analog span span {
    font-weight: 500;
}

.drugmenu {
    margin-top: 20px;
}

/* Drug - images */

.a_img img {
    border: none;
    display: inline-block;
    margin: auto;
    max-height: 200px;
    max-width: 200px;
    vertical-align: middle;
}

.a_img a:first-child {
    display: block;
    height: 90%;
    text-align: center;
}

.a_img a:active {
    outline: 0;
}

.a_img a:hover {
    border: none !important;
}

.a_img {
    border: none;
    float: left;
    height: 220px;
    padding: 5px;
    position: relative;
    width: 220px;
}

.a_img span.img-text {
    bottom: 10px;
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
}

table.druglist td {
    width: 200px !important;
    height: 160px !important;
    font-size: 1.2em;
    vertical-align: top;
    background: #fff;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 20px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 3px 3px #AAA;
    position: relative;
    padding-bottom: 10px;
}

table.druglist td:hover {
    box-shadow: none;
    position: relative;
    top: 2px;
}

table.druglist td:hover {
    cursor: pointer;
}

table.druglist a {
    border-bottom: none;
    display: inline-block;
    max-width: 130px;
}

table.druglist div:first-child {
    color: #333;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
}

.drugs div {
    margin-top: 2px;
    padding-left: 20px;
}

.drug-left {
    float: left;
    width: 310px;
    padding-bottom: 20PX;
    text-align: center;
}

.drug-right {
    float: right;
    width: 610px;
}

.drugdesc td{
    vertical-align: top;
    font-size: 1.2em;
}

#ifr_simple_drug_rate {
    height: 250px;
}

#ifr_drug_rate {
    height: 630px;
}

.drugarea-tbl{
    width: 100%;
}

.drugarea-tbl td{
    width: 33%;
}

.drugarea-tbl a {
    font-size: 1.7em;
    line-height: 30px;
    margin: 5px;
    text-decoration: none;
    border-bottom: 1px dotted gray;
}

.drugarea-tbl .cnt {
    color: #0091FF;
}

.drugarea-tbl a:hover {
    border-color: red;
}

.avatar_td{
    width: 150px;
}

.print {
    display: none;
}

.forprint {
    text-align: center;
    position: relative;
    bottom: 5px;
}



.pharm_godnost div {
    width: 40px;
    font-size: 24px;
    color: #A42F22;
    font-weight: 500;
    font-style: italic;
    left: 29px;
    top: 11px;
    background: #FFF url('/static/_v1/pt/icons/storage-months.png') no-repeat scroll left center / 81px auto;
    padding: 12px 15px 15px 30px;
    overflow: visible;
    height: 40px;
}

.drug_forms {
    width: 100%;
    border-collapse: collapse;
}

.drug_forms th {
    font-size: 14px;
    color: gray;
}

.drug_forms td, .drug_forms th {
    text-align: left;
    padding: 5px;
}

.drug_forms tr:not(:last-child) td, .drug_forms th {
    border-bottom: 1px solid #CCCCCC;
}

.drug_forms td:first-child, .drug_forms th:first-child {
    width: 90px;
}

.drug_forms td:first-child {
    text-align: center;
}

.drug_dozirovka, .drug_kolvo {
    font-size: 1.5em;
}

.drug_form_img {
    width: 70px;
    height: 70px;
    background-color: #A42F22;
    border-radius: 10px;
}

.drug_form_img img {
    max-width: 60px;
    max-height: 60px;
    margin-top: 4px;
}

.drug_kolvo {
    max-width: 80px;
}


.drug_protivo {
    width: 60px;
    margin-right: 10px;
    vertical-align: middle;
}



.drug_rate_form {
    background: #CCE9FF none repeat scroll 0% 0%;
    padding: 15px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
}

.doctor_drugrate .fio_ph {
    font-weight: 500;
    margin-top: 15px;
}

.doctor_drugrate .doctor_ph img {
    margin: 0px 10px 0px 0px;
    overflow: hidden;
}

.druglist_ratesnum {
    float: right;
    border-bottom-left-radius: 35px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 35px;
    height: 35px !important;
    text-align: right !important;
    font-size: 1.3em;
    padding: 5px 5px 0 0;
    position: relative;
    bottom: 1px;
    left: 1px;
    background-color: rgb(204, 233, 255);
}

.druglist_ratesnum:hover {
    cursor: pointer;
}

.druglist_drugname {
    float: left;
    width: 125px;
    font-weight: 500;
}

.druglist_drugname a {
    text-decoration: none;
    display: inline !important;
}

.drug_country {
    max-width: 50px;
    border: 1px solid #ccc;
}

.drug_manufacture {
    float: right;
}

.drug_manufacture img {
    float: right;
    margin-bottom: 5px;
}

.drug_manufacture_img {
    max-width: 150px;
    max-height: 100px;
}

.drug_name_latin {
    position: relative;
    color: gray;
}

.drugs_photo_header {
    width: 0;
    white-space: nowrap;
}

#tabs_instruction table {
    border: 0;
    border-collapse: collapse;
    width: 100%;
}

#tabs_instruction td {
    border: 1px solid #ccc;
    padding: 3px;
}

#find_drug {
    height: 25px;
}

.manuf_list {
    width: 100%;
    border-collapse: collapse;
}

.manuf_container td {
    border-top: 1px solid #ddd;
    padding: 10px;
}

.manuf_container a {
    text-decoration: none;
}

.manuf_container:hover {
   background-color: #f8f8f8;
    cursor: pointer;
}

.manuf_container:hover a {
    color: red;
}

.manuf_container img {
    max-width: 140px;
    max-height: 70px;
}

.manuf_container .manuf_name {
    font-size: 18px;
}

.manuf_img_container {
    width: 150px;
    vertical-align: middle;
}

.manuf_container .manuf_country {
    width: 100px;
}

.manuf_header td {
    padding: 0 0 5px 10px;
}

.manuf_drugs_num {
    text-align: center;
}

#user_menu_rates.user_menu_link_only {
    display: none;
}

.pharm_tags_cols {
    column-count: 4;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
}

.pharm_tags_cols a {
    text-decoration: none;
}

.pharm_tags_cols a:hover {
    text-decoration: underline;
}

.drugunion {
    font-size: 14px;
}

.view_as_link {
    background: none;
    background-color: transparent;
    color: black;
    border: none;
    padding: 0;
    display: inline;
    text-decoration: underline;
    outline: none;
}

.view_as_link:hover {
    background: none;
    background-color: transparent;
    color: red;
    cursor: pointer;
}

/* questions */

.drug_questions {
    margin-top: 20px;
}

.drug_questions table {
    border-collapse: collapse;
}

.super tr:last-of-type td {
    border-bottom: none;
}

.instruction_text {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin-top: 1em;
}

.instruction_text p {
    text-align: left;
}

.instruction_text table,
.instruction_text li {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
}

.instruction_block table {
    border-collapse: collapse;
}

.instruction_block table td {
    padding: 2px 5px;
    border: 1px solid #ccc;
}

.instruction_section {
    font-size: 1.2em;
    margin-bottom: 60px;
}

.instruction_section h3 {
    margin-bottom: 0;
}

.instruction_section h4 {
    -webkit-margin-after: 0.33em;
}

.instruction_section .protivo_icons {
    margin-top: 10px;
    margin-bottom: 20px;
}

.instruction_navigation {
    font-size: 1.2em;
    display: inline-block;
    float: left;
    margin-bottom: 2em;
}

.instruction_navigation ol {
    padding-left: 17px;
}

.instruction_navigation li {
    line-height: 1.5em;
}

.instructions_images {
    display: inline-block;
    max-width: 820px;
    text-align: left;
    margin-top: 20px;
}

.instructions_images h2 {
    margin-left: 60px;
}

.instructions_images span.img-text {
    bottom: 10px;
    display: inline-block;
    position: static;
    width: auto;
    margin-top: 3px;
}

.instructions_images .a_img img {
    max-height: 150px;
    border: 1px solid #ccc;
}

.instructions_images .a_img.forprint {
    max-height: 170px;
}

.mainpage > div > div .pharm_main_page__town_selector {
    margin-left: 0;
    position: inherit;
    top: inherit;
    margin-top: 10px;
    font-size: inherit;
}

.mainpage > div > div .pharm_main_page__town_selector .town_select.select2-container {
    width: 172px;
}

.block_main .pharm_main_page__town_selector a.select2-choice {
    font-size: 14px;
}

.pharm_main_page__go_town {
    height: 30px;
    width: 60px;
    line-height: 30px;
    font-size: 12px;
    margin-left: 6px;
}

.drug-right .drug_right__stars {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
}

.drug_right__rates_total_count {
    vertical-align: middle;
    font-size: 14px;
}

.drug_right__stars_tooltipster td {
    padding-left: 0;
    width: 0;
}

.drug_right__stars_tooltipster .rating {
    margin-left: 2px;
}

.rate_stars .rate_stars__stars {
    float: none;
}

.rate_stars {
    margin-left: 22px;
    margin-bottom: 10px;
}

.rates.drug_rates tr[itemprop="review"] td,
.rates.drug_comments tr.rate td {
    padding-bottom: 30px;
}

.rates.drug_rates img.avatar {
    width: 100px;
    object-fit: cover;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    white-space: normal;
}

.rates.drug_rates tr[itemprop="review"] .fio {
    text-decoration: none;
}

.rates.drug_rates tr[itemprop="review"] .fio:hover {
    color: red;
}

.mainpage_pharm_groups_divider {
    width: 300px;
}

.mainpage_pharm_groups_container td {
    line-height: 21px;
    white-space: nowrap;
}

.mainpage_pharm_groups_container td:not(:last-child) {
    padding-right: 20px;
}

.mainpage_pharm_group__towns {
    float: left;
    padding-left: 0;
    margin-top: 0;
}

.mainpage_pharm_group__title {
    padding-bottom: 15px;
}

.mainpage_pharm_group__title a {
    font-size: 2em;
    text-decoration: none;
    border-bottom: 1px dotted #aaa;
    margin-left: -10px;
}

.mainpage_pharm_group__towns:not(:first-of-type) {
    margin-left: 20px;
}

.mainpage_pharm_group__towns a,
.mainpage_pharm_group a {
    text-decoration: none;
    font-size: 14.4px;
    border-bottom: 1px dotted #aaa;
}

.mainpage_pharm_group ul.mainpage_pharm_group__towns li a  {
    font-size: 14px;
}

.mainpage_pharm_group__towns li {
    list-style: none;
    line-height: 20px;
}

.mainpage_pharm_group {
    vertical-align: top;
    font-size: 14px;
}

table.drug_list {
    border-collapse: collapse;
}

.drug_list {
    width: 728px;
}

table.drug_list td {
    vertical-align: top;
}

.drug_list__avatar_section,
.drug_list__info_section {
    padding: 25px 5px;
}

.drug_list__avatar_section {
    padding-right: 20px;
}

.drug_list__drug_name {
    color: #0071ff;
    font-weight: 500;
    position: relative;
    bottom: 3px;
    border-bottom: medium none;
    text-decoration: none;
    font-size: 1.6em;
}

.drug_list__rate_quote {
    margin-left: 0;
    margin-right: 0;
    color: gray;
}

.drug_list__rates_count {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px dotted gray;
    text-decoration: none;
    color: #858585;
    font-weight: 500;
    margin-right: 20px;
    padding-top: 5px;
}

.analogs_for_use_link {
    text-decoration: none;
    color: #0071ff;
}

.analogs_for_use_gray {
    font-weight: normal !important;
    color: gray;
}

.analogs_left, .analogs_right {
    display: inline-block;
}

.analogs_right {
    margin-left: 10px;
}

.reviews_count {
    font-weight: 500 !important;
    color: gray;
}

